<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список серверов ({{ servers.length }})</h2>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="createServer()"
        >Добавить сервер</b-button
        >
      </div>
    </div>
    <div style="margin-top: 60px;">
      <div class="draggable-item" v-for="item in servers" :key="item.id">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="title is-4">{{ item.name }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="title is-4">{{ item.ip }}:{{ item.port }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered" style="float: right">
            <div>
              <v-row>
                <v-col>
                  <b-button icon-right="chevron-right" @click="updateServer(item)" v-if="authUser.role ==='admin'"
                  >Обновить сервер</b-button
                  >
                </v-col>
                <v-col v-if="authUser.role === 'admin' || authUser.role === 'superuser'">
                  <b-tooltip label="Удалить сервер">
                    <b-button
                      type="is-danger"
                      icon-right="delete"
                      @click="deleteServer(item)"
                    >
                    </b-button>
                  </b-tooltip>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ServerDetailsModal></ServerDetailsModal>
    <ConfirmDeletionModal></ConfirmDeletionModal>
  </div>
</template>

<script>
import ServerDetailsModal from '@/app/client/servers/shared/modals/serverDetailsModal.vue';
import ConfirmDeletionModal from '@/app/client/servers/shared/modals/confirmDeletionServerModal.vue';

export default {
  name: "ServersList",
  components: {
    ServerDetailsModal,
    ConfirmDeletionModal,
  },
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("GET_SERVERS");
  },
  computed: {
    authUser() {
      return this.$store.getters.AUTH_USER;
    },
    servers() {
      return this.$store.getters.GET_SERVERS;
    },
  },
  methods: {
    createServer() {
      this.$store.dispatch("SERVER_DETAILS_MODAL", true);
    },
    updateServer(server) {
      this.$store.dispatch("CURRENT_SERVER", server);
      this.$store.dispatch("SERVER_DETAILS_MODAL", true);
    },
    deleteServer(server) {
      this.$store.dispatch("CURRENT_SERVER", server);
      this.$store.dispatch("CONFIRM_DELETION_SERVER_MODAL", true);
    },
  },
};
</script>

<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
